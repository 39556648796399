export const PLAN_BUILDER_HIT = 'plan_builder';
export const PLAN_BUILDER_HIT_FLOW_NAME = 'suf_web_plan_builder';
export const PLAN_BUILDER_HIT_SCREEN_NAME = 'SUF - Plan Builder';
export const PLAN_BUILDER_URL = '/plan-builder';
export const HULU_WITH_LIVE_TV_HULU_ADS = 'hulu-with-live-tv-hulu-ads';
export const AD_SUPPORTED_HULU_ONLY = 'ad-supported-hulu-only';
export const HULU_NO_ADS_WITH_LIVE_TV_AD_FREE_TRIO_PREMIUM =
  'hulu-no-ads-with-live-tv-ad-free-trio-premium';
export const KEEP_PLAN = 'keep-plan';
export const TRIO_PREMIUM = 'trio-premium';
export const HULU_NO_ADS_WITH_LIVE_TV_HULU_NO_ADS =
  'hulu-no-ads-with-live-tv-hulu-no-ads';
