import React from 'react';

import { ExpPlanBuilderAnswer } from './ExpPlanBuilderAnswer';

export const ExpPlanBuilderAnswerSection = ({
  answers,
  questionIndex,
  isEnabled,
  values,
  getMap,
  scrollDelayTime,
  setIsLiveTVSelected,
}) => {
  return (
    <div className="answers">
      {answers.map((answer, index) => {
        return (
          <ExpPlanBuilderAnswer
            key={`question-${questionIndex}-answer-${index}`}
            answer={answer}
            answerIndex={index}
            questionIndex={questionIndex}
            isEnabled={isEnabled}
            values={values}
            getMap={getMap}
            scrollDelayTime={scrollDelayTime}
            setIsLiveTVSelected={setIsLiveTVSelected}
          />
        );
      })}
    </div>
  );
};
