import * as Billboard from './Billboard';
import * as CallToAction from './CallToAction';
import {
  BaseCollection,
  SimpleCollection,
  EditorialCollection,
  AdaptiveCollection,
  PortraitCollection,
  GridCollection,
  EpisodeCollection,
  CollectionDetails,
} from './Collections';
import * as CollectionTabs from './CollectionTabs';
import * as ConnectedLoginContainer from './ConnectedLoginContainer';
import * as ContentTabs from './ContentTabs';
import * as ContentWithAnchor from './ContentWithAnchor';
import * as DetailEntityMasthead from './DetailEntityMasthead';
import * as Devices from './Devices';
import * as DualCtaMasthead from './DualCtaMasthead';
import * as EmailUnsubscribe from './EmailUnsubscribe';
import * as ExitIntentModal from './ExitIntentModal';
import * as FAQs from './FAQs';
import * as Headline from './Headline';
import * as HeroSliderMasthead from './HeroSliderMasthead';
import * as HubNav from './HubNav';
import * as HubTabs from './HubTabs';
import * as Library from './Library';
import * as ListCard from './ListCard';
import * as LoginForm from './LoginForm';
import * as LoginModal from './LoginModal';
import * as LogoWall from './LogoWall';
import * as Masthead from './Masthead';
import * as MastheadCarousel from './MastheadCarousel';
import * as MediaContainer from './MediaContainer';
import * as MediaWithText from './MediaWithText';
import * as MicroSite from './MicroSite';
import * as Modal from './Modal';
import * as Names from './names';
import * as Navigation from './Navigation';
import * as NetworksGeo from './NetworksGeo';
import * as NetworksSearchModal from './NetworksSearchModal';
import * as NetworksSearchModalWithGeo from './NetworksSearchModalWithGeo';
import * as PlanComparisonChart from './PlanComparisonChart';
import * as PlanComparisonChartWithToggle from './PlanComparisonChartWithToggle';
import * as PlansContainer from './PlansContainer';
import * as QRCodeContainer from './QRCodeContainer';
import * as Ribbon from './Ribbon';
import * as SignupModal from './SignupModal';
import * as SpotlightTabs from './SpotlightTabs';
import * as StaticHtml from './StaticHtml';
import * as SupportedDevices from './SupportedDevices';
import * as TierModal from './TierModal';
import * as Toaster from './Toaster';
import * as TopHat from './TopHat';
import * as ValueProps from './ValueProps';

// Experimental
import * as ExpDropdownPlanComparisonChart from '!app/experimental/components/ExpDropdownPlanComparisonChart';
import * as ExpEmailCaptureMasthead from '!app/experimental/components/ExpEmailCaptureMasthead';
import * as ExpGrid from '!app/experimental/components/ExpGrid';
import * as ExpLiveTVComparisonChart from '!app/experimental/components/ExpLiveTVComparisonChart';
import * as ExpMastheadDualCTA from '!app/experimental/components/ExpMastheadDualCTA';
import * as ExpNoFeesMinimalist from '!app/experimental/components/ExpNoFeesMinimalist';
import * as ExpOneClickReconnectModal from '!app/experimental/components/ExpOneClickReconnectModal';
import * as ExpPlanBuilder from '!app/experimental/components/ExpPlanBuilder';
import * as ExpPlanBuilderEscapeHatch from '!app/experimental/components/ExpPlanBuilderEscapeHatch';
import * as ExpPlanBuilderEscapeHatchLiveTVFirst from '!app/experimental/components/ExpPlanBuilderEscapeHatchLiveTVFirst';
import * as ExpPlanBuilderEscapeHatchV2 from '!app/experimental/components/ExpPlanBuilderEscapeHatchV2';
import * as ExpPlanComparisonChart from '!app/experimental/components/ExpPlanComparisonChart';
import * as ExpPlanComparisonChartCategoryDropdown from '!app/experimental/components/ExpPlanComparisonChartCategoryDropdown';
import * as ExpPlanComparisonChartMultiTab from '!app/experimental/components/ExpPlanComparisonChartMultiTab';
import * as ExpPlanComparisonChartMultiTabV2 from '!app/experimental/components/ExpPlanComparisonChartMultiTabV2';
import * as ExpPlanComparisonChartWithToggle from '!app/experimental/components/ExpPlanComparisonChartWithToggle';

const map = {
  [Names.AdaptiveCollection]: AdaptiveCollection,
  [Names.BaseCollection]: BaseCollection,
  [Names.Billboard]: Billboard,
  [Names.CallToAction]: CallToAction,
  [Names.CollectionDetails]: CollectionDetails,
  [Names.CollectionTabs]: CollectionTabs,
  [Names.CollectionTabsMasthead]: CollectionTabs,
  [Names.ConnectedLoginContainer]: ConnectedLoginContainer,
  [Names.ContentTabs]: ContentTabs,
  [Names.ContentWithAnchor]: ContentWithAnchor,
  [Names.DetailEntityMasthead]: DetailEntityMasthead,
  [Names.Devices]: Devices,
  [Names.DualCtaMasthead]: DualCtaMasthead,
  [Names.EditorialCollection]: EditorialCollection,
  [Names.EmailUnsubscribe]: EmailUnsubscribe,
  [Names.EpisodeCollection]: EpisodeCollection,
  [Names.ExitIntentModal]: ExitIntentModal,
  [Names.FAQs]: FAQs,
  [Names.GridCollection]: GridCollection,
  [Names.Headline]: Headline,
  [Names.HeroSliderMasthead]: HeroSliderMasthead,
  [Names.HubNav]: HubNav,
  [Names.HubTabs]: HubTabs,
  [Names.Library]: Library,
  [Names.ListCard]: ListCard,
  [Names.LoginForm]: LoginForm,
  [Names.LoginModal]: LoginModal,
  [Names.LogoWall]: LogoWall,
  [Names.Masthead]: Masthead,
  [Names.MastheadCarousel]: MastheadCarousel,
  [Names.MediaContainer]: MediaContainer,
  [Names.MediaWithText]: MediaWithText,
  [Names.MicroSite]: MicroSite,
  [Names.Modal]: Modal,
  [Names.Navigation]: Navigation,
  [Names.NetworksGeo]: NetworksGeo,
  [Names.NetworksSearchModal]: NetworksSearchModal,
  [Names.NetworksSearchModalWithGeo]: NetworksSearchModalWithGeo,
  [Names.PlanComparisonChart]: PlanComparisonChart,
  [Names.PlanComparisonChartWithToggle]: PlanComparisonChartWithToggle,
  [Names.PlansContainer]: PlansContainer,
  [Names.PortraitCollection]: PortraitCollection,
  [Names.QRCodeContainer]: QRCodeContainer,
  [Names.Ribbon]: Ribbon,
  [Names.SignupModal]: SignupModal,
  [Names.SpotlightTabs]: SpotlightTabs,
  [Names.SimpleCollection]: SimpleCollection,
  [Names.StaticHtml]: StaticHtml,
  [Names.SupportedDevices]: SupportedDevices,
  [Names.TierModal]: TierModal,
  [Names.Toaster]: Toaster,
  [Names.TopHat]: TopHat,
  [Names.ValueProps]: ValueProps,

  // Experimental
  [Names.ExpPlanComparisonChart]: ExpPlanComparisonChart,
  [Names.ExpPlanComparisonChartWithToggle]: ExpPlanComparisonChartWithToggle,
  [Names.ExpPlanComparisonChartMultiTab]: ExpPlanComparisonChartMultiTab,
  [Names.ExpPlanComparisonChartMultiTabV2]: ExpPlanComparisonChartMultiTabV2,
  [Names.ExpGrid]: ExpGrid,
  [Names.ExpNoFeesMinimalist]: ExpNoFeesMinimalist,
  [Names.ExpEmailCaptureMasthead]: ExpEmailCaptureMasthead,
  [Names.ExpPlanBuilder]: ExpPlanBuilder,
  [Names.ExpPlanBuilderEscapeHatch]: ExpPlanBuilderEscapeHatch,
  [Names.ExpPlanBuilderEscapeHatchLiveTVFirst]: ExpPlanBuilderEscapeHatchLiveTVFirst,
  [Names.ExpPlanBuilderEscapeHatchV2]: ExpPlanBuilderEscapeHatchV2,
  [Names.ExpLiveTVComparisonChart]: ExpLiveTVComparisonChart,
  [Names.ExpMastheadDualCTA]: ExpMastheadDualCTA,
  [Names.ExpPlanComparisonChartCategoryDropdown]: ExpPlanComparisonChartCategoryDropdown,
  [Names.ExpDropdownPlanComparisonChart]: ExpDropdownPlanComparisonChart,
  [Names.ExpOneClickReconnectModal]: ExpOneClickReconnectModal,
};

const getComponent = (name) => {
  if (name in map) {
    const component = map[name];
    return component.view;
  }
  return null;
};

const getReducerMap = (name, model) => {
  if (name in map) {
    const component = map[name];
    if (typeof component.reducerMap === 'function') {
      return component.reducerMap(model);
    }
    return component.reducerMap;
  }
  return null;
};

export { getComponent, getReducerMap };
