import { ThemeProvider } from '@emotion/react';
import { oneHuluDarkTheme } from '@hulu/web-ui/style/createTheme';
import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import { withFormik } from 'formik';
import React, { useEffect, useRef } from 'react';

import '../stylesheet/ExpPlanBuilder.scss';
import {
  PLAN_BUILDER_HIT_FLOW_NAME,
  PLAN_BUILDER_HIT_SCREEN_NAME,
} from '../constants';

import { ExpPlanBuilderFooter } from './ExpPlanBuilderFooter';
import { ExpPlanBuilderQuestionContainer } from './ExpPlanBuilderQuestionContainer';

import { goToSignup } from '!app/lib/signupUtils';
import {
  fireSubscriptionStart,
  fireSubscriptionStepStart,
  fireUserInteraction,
} from '!app/metrics/fireEvent';

const ExpPlanBuilder = (props) => {
  const {
    model: {
      title,
      subtitle,
      startPriceText,
      questionSections,
      bottomSection,
      footer,
      scrollDelayTime,
      hideBundleSection,
    },
    values,
    user,
  } = props;
  useEffect(() => {
    fireSubscriptionStart(PLAN_BUILDER_HIT_FLOW_NAME);
    fireSubscriptionStepStart(
      PLAN_BUILDER_HIT_FLOW_NAME,
      PLAN_BUILDER_HIT_SCREEN_NAME
    );
    // scroll to top of page if user refreshes
    window.scrollTo(0, 0);
  }, []);

  // used for scrolling to the next question
  const questionsRef = useRef(null);
  // creating a map of refs and accessing ref by id
  const getMap = () => {
    if (!questionsRef.current) {
      questionsRef.current = new Map();
    }
    return questionsRef.current;
  };

  const [isLiveTVSelected, setIsLiveTVSelected] = React.useState(false);

  return (
    <ThemeProvider theme={oneHuluDarkTheme}>
      <form
        onSubmit={(event) => handleSubmit(event, values, props)}
        className={classNames('exp-plan-builder-escape-hatch-formv2', {
          '--expanded': values.isFooterExpanded,
        })}
      >
        <div className="exp-plan-builder">
          <div className="exp-plan-builder-container-width">
            <div className="title-section">
              <Text variant="title24" className="plan-builder-title">
                {title}
              </Text>
              <Text variant="body16" className="plan-builder-subtitle">
                {subtitle}
              </Text>
              <div
                className="start-price-text"
                dangerouslySetInnerHTML={{ __html: startPriceText }}
              />
            </div>
            <div className="question-section">
              {questionSections.map((sectionData, index) => {
                // Hiding the second question section if the user has selected Live TV as it includes the bundle.
                if (
                  sectionData.sectionId === 'bundle' &&
                  isLiveTVSelected &&
                  hideBundleSection
                ) {
                  return null;
                }
                return (
                  <ExpPlanBuilderQuestionContainer
                    key={`question-${index}`}
                    sectionData={sectionData}
                    index={index}
                    values={values}
                    getMap={getMap}
                    scrollDelayTime={scrollDelayTime}
                    isLiveTVSelected={isLiveTVSelected}
                    setIsLiveTVSelected={setIsLiveTVSelected}
                  />
                );
              })}
            </div>
            <div
              className={classNames('bottom-section', {
                '--expanded': values.isFooterExpanded,
              })}
            >
              <div
                className="bottom-content"
                dangerouslySetInnerHTML={{ __html: bottomSection }}
              />
            </div>
          </div>
        </div>
        <ExpPlanBuilderFooter footer={footer} values={values} user={user} />
      </form>
    </ThemeProvider>
  );
};

export const handleSubmit = ({
  event,
  signupUrl,
  user,
  hitTargetDisplayName,
}) => {
  event.preventDefault();
  fireUserInteraction(
    'cta_primary',
    'driver_click',
    'click',
    true,
    hitTargetDisplayName
  );
  goToSignup({}, signupUrl, user);
};

// answerIdToPlanIdMap is map of answer ids to their associated plan id
const mapPropsToValues = (props) => {
  const initialValues = {};
  const answerIdToPlanIdMap = {};
  props.model.questionSections.forEach((question, index) => {
    initialValues[`question${index}`] = null;
    (question.answers || []).forEach((answer) => {
      answerIdToPlanIdMap[answer.id] = answer.planId;
    });
  });
  return {
    ...initialValues,
    isFooterExpanded: false,
    hasAnsweredAllQuestions: false,
    questionSections: props.model.questionSections || [],
    footerPlanId: props.model.footer.defaultPlanId,
    answerIdToPlanIdMap,
  };
};
export default withFormik({
  mapPropsToValues,
  handleSubmit,
  displayName: 'ExpPlanBuilder',
})(ExpPlanBuilder);
