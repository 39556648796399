import Text from '@hulu/web-ui/Text';
import { useEffect, useState } from 'react';

import Modal from '!app/components/Modal/view/Modal';
import { getHost } from '!app/lib/config';
import { COOKIES } from '!app/lib/constants';
import { getCookie } from '!app/lib/cookies';
import { ComponentLogger } from '!app/lib/logger';
import { getPreviousSubscription } from '!app/lib/signupUtils';
import CTAButton from '!app/share/CTAButton';

import '../stylesheet/ExpOneClickReconnectModal.scss';

// Component Ids: http://hype.staging.hulu.com/components
// If this is productionalized, move to https://github.prod.hulu.com/WebTeam/hitch/blob/develop/app/lib/constants.js
export const ESPN = 16;
export const DISNEY_ADS = 19;
export const DISNEY_NO_ADS = 20;
export const MAX_ADS = 21;
export const MAX_NO_ADS = 22;
export const HULU_LIVE = 6;
export const HULU = 1;

/**
 * This retrieves the correct image logo for the component Ids.
 * The order of the if statements matter so do not reorder them.
 *
 * @param {*} components - list of component Ids representing the previous subscriptions
 * @param {*} images - list of urls of images for logos
 * @returns
 */
export const getLogoImages = (components, images) => {
  const hasEspn = components?.includes(ESPN);
  const hasMax =
    components?.includes(MAX_ADS) || components?.includes(MAX_NO_ADS);
  const hasDisney =
    components?.includes(DISNEY_ADS) || components?.includes(DISNEY_NO_ADS);
  const hasHuluLive = components?.includes(HULU_LIVE);

  // hulu live; hulu live tv comes in a bundle that includes disney+ and espn, this conditional should be first
  if (hasHuluLive) {
    return <img alt="hulu live logo" src={images?.huluLiveTv} />;
  }

  // hulu disney espn
  if (hasEspn && hasDisney) {
    return <img alt="hulu disney espn logo" src={images?.huluDisneyEspn} />;
  }
  // hulu disney max
  if (hasMax && hasDisney) {
    return <img alt="hulu disney max logo" src={images?.huluDisneyMax} />;
  }
  // hulu disney
  if (hasDisney) {
    return <img alt="hulu disney logo" src={images?.huluDisney} />;
  }

  // hulu only
  return <img alt="hulu logo" src={images?.hulu} />;
};

const ExpOneClickReconnectModal = ({ model }) => {
  const { headline, description, button, images, link } = model;

  const [isOpen, setIsOpen] = useState(false);
  const [
    previousSubscriptionComponentIds,
    setPreviousSubscriptionComponentIds,
  ] = useState([]);

  const cartAbandonmentCookie = getCookie(COOKIES.CART_ABANDONMENT);

  useEffect(() => {
    getPreviousSubscription()
      .then((res) => res.json())
      .then((res) => {
        if (res && res.component_ids?.length > 0) {
          setPreviousSubscriptionComponentIds(res.component_ids);
          setIsOpen(true);
        }
      })
      .catch((err) => {
        ComponentLogger.error(
          `error trying to fetch the users previous subscription. Error: ${err}`
        );
      });
  }, []);

  // Navigates to Billing page with the correct previous plan selected
  const onClickCTA = () => {
    const componentIdsQueryParam = encodeURIComponent(
      JSON.stringify(previousSubscriptionComponentIds)
    );
    const signupUrl = `${getHost(
      'signup'
    )}/plans?component_ids=${componentIdsQueryParam}`;
    window.location.assign(signupUrl);
  };

  // Navigates to Plans page
  const onClickLink = () => {
    const signupUrl = `${getHost('signup')}/plans`;
    window.location.assign(signupUrl);
  };

  // Do not show reconnect modal for users with cart abandonment cookie.
  if (cartAbandonmentCookie) {
    return null;
  }

  return isOpen ? (
    <Modal
      model={{ id: 'reconnect-modal' }}
      onModalClosed={() => setIsOpen(false)}
      toggleModal={() => {}}
      shown={isOpen}
      className="one-click-reconnect-modal-container"
    >
      <div className="one-click-modal-body">
        <div className="modal-logos">
          {previousSubscriptionComponentIds &&
            getLogoImages(previousSubscriptionComponentIds, images)}
        </div>
        <Text className="modal-headline">{headline}</Text>
        <Text className="modal-description">{description}</Text>
        <CTAButton useStyle="green_with_black_text" onClick={onClickCTA}>
          {button}
        </CTAButton>
        <button
          aria-label={link}
          className="button-link modal-link"
          onClick={onClickLink}
        >
          {link}
        </button>
      </div>
    </Modal>
  ) : null;
};

export default ExpOneClickReconnectModal;
