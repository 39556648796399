import Text from '@hulu/web-ui/Text';
import classNames from 'classnames';
import { find } from 'lodash';
import React from 'react';

import {
  AD_SUPPORTED_HULU_ONLY,
  HULU_NO_ADS_WITH_LIVE_TV_HULU_NO_ADS,
  HULU_WITH_LIVE_TV_HULU_ADS,
} from '../constants';

import { ExpPlanBuilderAnswerSection } from './ExpPlanBuilderAnswerSection';

/**
 * Determines if the current question should be disabled if the previous question has been answered
 * @param index index of the current question
 * @param values form values
 * @returns true if previous question is null/not answered, false if it not null/is answered
 */
const isCurrentQuestionEnabled = (index, values, isLiveTVSelected) => {
  if (index === 0) {
    return true;
  }

  // Enables the third question if the second question is hidden.
  if (index === 2 && isLiveTVSelected) {
    return true;
  }

  const previousQuestionIndex = index - 1;
  return values[`question${previousQuestionIndex}`] !== null;
};

/**
 * For question 2, the answers rendered are dependent on the answer of question 1
 * For question 3, the answers rendered are dependent on the answer of question 2
 * in question 1's answers, there is a field "nextAnswerIds"
 * @param index index of the current question
 * @param values form values
 * @param answers array of answers
 * @returns answers array that should be rendered for this question
 */
// TODO: consider using nextQuestionId instead of nextAnswerIds to determine what to render
const getAnswersForQuestion = (index, values, answers) => {
  let renderedAnswers = answers;

  if (index === 1) {
    // Fall back to the default answer of ad-supported-hulu-only.
    const previousAnswer = values.question0 || AD_SUPPORTED_HULU_ONLY;
    const findIdsToRender = values.questionSections[0].answers.find(
      (answer) => {
        return answer.id === previousAnswer;
      }
    );

    renderedAnswers =
      findIdsToRender.nextAnswerIds.map((id) => {
        return find(answers, { id });
      }) || [];
  }

  if (index === 2) {
    const liveTVAnswerIds =
      values.question0 === HULU_WITH_LIVE_TV_HULU_ADS
        ? [HULU_NO_ADS_WITH_LIVE_TV_HULU_NO_ADS, HULU_WITH_LIVE_TV_HULU_ADS]
        : [];

    // Since we're skipping the second question, we need a fallback for that answer, to not break the array of selections.
    const previousAnswer = values.question1 || AD_SUPPORTED_HULU_ONLY;

    // Finds the answer object that matches the previous answer.
    const findIdsToRender = values.questionSections[1].answers.find(
      (answer) => {
        return answer.id === previousAnswer;
      }
    );

    // From above, if user selects hulu with live tv, use those answers, otherwise use what is found in findIdsToRender.
    const answerIdsToRender = liveTVAnswerIds.length
      ? liveTVAnswerIds
      : findIdsToRender.nextAnswerIds;

    renderedAnswers =
      answerIdsToRender.map((id) => {
        return find(answers, { id });
      }) || [];
  }

  return renderedAnswers;
};

export const ExpPlanBuilderQuestionContainer = ({
  sectionData,
  index,
  values,
  getMap,
  scrollDelayTime,
  isLiveTVSelected,
  setIsLiveTVSelected,
}) => {
  const { imagePath, question, description, linkText, answers } = sectionData;

  const renderedAnswers = getAnswersForQuestion(index, values, answers);
  const isEnabled = isCurrentQuestionEnabled(index, values, isLiveTVSelected);
  const questionId = `question${index}`;

  // ref callback that sets the ref as the DOM node or null
  const refCallback = (node) => {
    const map = getMap();
    if (node) {
      map.set(questionId, node);
    } else {
      map.delete(questionId);
    }
  };

  return (
    <div
      className={classNames('question-container', {
        '--disabled': !isEnabled,
      })}
      key={questionId}
      ref={refCallback}
    >
      <div className="question">
        {imagePath && <img src={imagePath} alt="icon" role="presentation" />}
        <Text variant="subtitle18">{question}</Text>
        <div
          className="question-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        {linkText && (
          <div
            className={classNames('question-linkText', {
              '--disabled': !isEnabled,
            })}
            dangerouslySetInnerHTML={{ __html: linkText }}
          />
        )}
      </div>
      <ExpPlanBuilderAnswerSection
        answers={renderedAnswers}
        questionIndex={index}
        isEnabled={isEnabled}
        values={values}
        getMap={getMap}
        scrollDelayTime={scrollDelayTime}
        setIsLiveTVSelected={setIsLiveTVSelected}
      />
      <hr />
    </div>
  );
};
